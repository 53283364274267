import React, { CSSProperties, useMemo, useCallback, useState, useEffect } from 'react';
import { FaAirbnb, FaChevronRight } from 'react-icons/fa';
import { createStyleSheet, mergeStyles } from '../CommonJs/CommonReact';
import { flexCenter, trans } from '../style/style';
import Glitch from './Glitch';
import Cover from './Cover';
import { useAnimatedValues, AnimatedValue, AnimatedWaitHandles, useAsync } from '../CommonJs/CommonHooks';
import { useWindowSize } from '../CommonJs/WindowSize';
import Counter from './Counter';
import ImageLoader from './ImageLoader';
import { AudioPlayer } from '../lib/AudioPlayer';
import { useApp } from '../lib/hooks';

const abColor='#FF5A5F';
const barHeight='0.5rem';
const timeMulti=2.5;
const minLoaderSize='2rem';

type StackContentType='usd'|'link';

interface StackContent
{
    bgImage:string;
    colorA:string;
    colorB:string;
    colorC:string;
    titleA:string;
    smallContent?:boolean;
    hideNext?:boolean;
    contentType:StackContentType;
    content:any;
    altContent?:any;
    contentColor?:any;
    audioFile?:string;
}

interface TimelineItem
{
    progress?:number;
    flip?:boolean;
    glitch?:boolean;
    content?:StackContent;
    noPause?:boolean;
}
const baseCt=200;



const defaultTimeline:AnimatedValue<TimelineItem>[]=[

    {value:{progress:0},duration:10*timeMulti},
    {value:{progress:15},duration:1000*timeMulti},
    {value:{progress:40},duration:300*timeMulti,waitFor:'results'},
    {value:{progress:50,glitch:true},duration:50*timeMulti},
    {value:{progress:51},duration:500*timeMulti},
    {value:{progress:80,flip:true},duration:50*timeMulti,waitFor:'imagesLoaded'},
    {value:{progress:99},duration:100*timeMulti},
    {value:{progress:100},duration:200*timeMulti},//7


    {value:{progress:baseCt+1},duration:300},
    {value:{progress:baseCt+2},duration:300},
    {value:{progress:baseCt+3},duration:300},

    {value:{progress:baseCt+4},duration:50},//11
];

interface ContentResult
{
    timeline:AnimatedValue<TimelineItem>[];
    preloadImages:string[];
    complete:boolean;
    defaultContent:StackContent;
}

const defaultContentResult:ContentResult={
    timeline:defaultTimeline,
    preloadImages:[],
    complete:false,
    defaultContent:{
        bgImage:'images/AirBnbRoom.jpg',
        colorA:'#333333',
        colorB:'#777777',
        colorC:'#aaaaaa',
        titleA:'Default Title',
        smallContent:true,
        contentType:'usd',
        content:10
    },
}

interface AirBnBViewProps
{
    src:string;
}

export default function AirBnBView({
    src
}:AirBnBViewProps){

    const {config,http}=useApp();
    const cdn=config.CdnBaseUrl;
    const {width:deviceWidth}=useWindowSize();

    const waitHandles=useMemo<AnimatedWaitHandles>(()=>({
        imagesLoaded:false
    }),[]);
    const onImagesLoaded=useCallback(()=>{
        waitHandles.imagesLoaded=true;
    },[waitHandles]);

    const {timeline,defaultContent,preloadImages,complete}=useAsync<ContentResult,ContentResult>(defaultContentResult,async ()=>{
        if(!src){
            return defaultContentResult;
        }
        const stacks=await http.getAsync(src) as StackContent[];

        if(!Array.isArray(stacks) || !stacks.length){
            return defaultContentResult;
        }

        const preloadImages:string[]=[];
        const timeline=[...defaultTimeline];
        let lastCompContent:StackContent=stacks[0];
        let compIndex=0;
        function slideComp(content:StackContent):AnimatedValue<TimelineItem>[]{
            const d=lastCompContent;
            lastCompContent=content;
            return [
                {value:{content:{...d,audioFile:undefined},glitch:true,noPause:true},duration:500,
                    waitFor:'slideIndex'+(compIndex++)},
                {value:{content:content,glitch:true},duration:3000},
                {value:{content:{...content}},duration:100},
            ];
        }

        for(let i=0;i<stacks.length;i++){

            const s=stacks[i];
            if(!s){
                continue;
            }

            if(s.bgImage){
                preloadImages.push(s.bgImage);
            }

            if(i>0){
                const items=slideComp(s);
                for(let i of items){
                    timeline.push(i);
                }
            }
        }

        timeline.push({value:{content:stacks[stacks.length-1]},duration:1000});

        return {
            timeline,
            defaultContent:stacks[0],
            preloadImages,
            complete:true
        };


    },[src,http]);

    useEffect(()=>{
        waitHandles.results=complete;
    },[complete,waitHandles]);

    const [slideIndex,setSlideIndex]=useState(-1);
    useEffect(()=>{
        waitHandles['slideIndex'+slideIndex]=true;
    },[slideIndex,waitHandles]);

    const timelineItem=useAnimatedValues(timeline,waitHandles,[timeline]);
    const {progress:_p,flip,glitch,content,noPause}=timelineItem;
    const progress=_p===undefined?baseCt+4:_p;

    const showLoader=progress<100;

    const audioPlayer=useMemo(()=>new AudioPlayer(),[]);
    const loadNextAudio=useCallback((index:number)=>{
        if(index<0){
            index=0;
        }
        for(;index<timeline.length;index++){
            const url=timeline[index].value.content?.audioFile;
            if(url){
                audioPlayer.load(cdn+url);
                break;
            }
        }
    },[audioPlayer,cdn,timeline]);
    useEffect(()=>{
        if(cdn+content?.audioFile===audioPlayer.src){
            audioPlayer.play(1000);
        }else{
            audioPlayer.pause();
        }
    },[audioPlayer,content,cdn]);

    const loader=(
        <div style={mergeStyles(styles.loadRoot,!showLoader&&{
            minWidth:minLoaderSize,
            minHeight:minLoaderSize,
            left:'0.5rem',
            top:'0.5rem',
        })}>
            <div style={mergeStyles(styles.loader,flip?{
                transform:'rotate(180deg)',
                transformOrigin:'center',
                filter:flip?'blur(0.6px)':undefined,
                opacity:0.9
            }:null)}>
                <FaAirbnb style={styles.loaderIcon} color={abColor} size={showLoader?"5rem":minLoaderSize}/>
                <div style={mergeStyles(styles.bar,!showLoader&&{
                    width:'0rem',
                    marginTop:'0rem',
                    height:'0rem',
                    opacity:0
                })}>
                    <div style={mergeStyles(styles.barFill,{
                        width:(progress>100?100:progress)+'%',
                    })} />
                </div>
            </div>
        </div>
    );

    const inBlock:CSSProperties={transform:'translateX(0px)'}
    const outBlock:CSSProperties={transform:'translateX('+deviceWidth+'px)'}

    const blockContent=content||defaultContent;
    const lastSlideIn=progress>=baseCt+4;
    let renderedBlockContent:any=null;

    switch(blockContent.contentType){

        case 'usd':
            renderedBlockContent=(
                <>
                    $<Counter
                        duration={1000}
                        resetOnPause={true}
                        pause={(glitch || !lastSlideIn) && !noPause}
                        round={blockContent.content>20}
                        addCents={blockContent.content<20}
                        value={blockContent.content}/>
                </>
            );
            break;

        case 'link':
            renderedBlockContent=(
                <a href={blockContent.altContent} style={mergeStyles(styles.link,{
                    backgroundColor:blockContent.contentColor
                })}>
                    {blockContent.content}
                </a>
            );
            break;

    }


    const page=(
        <div style={styles.page}>
            <div style={mergeStyles(styles.contentBlock,progress>=baseCt+1?inBlock:outBlock,{
                backgroundImage:'url('+cdn+blockContent.bgImage+')'
            })} />
            <div style={mergeStyles(styles.contentBlock,progress>=baseCt+2?inBlock:outBlock,{
                backgroundColor:blockContent.colorA
            })}>{blockContent.titleA}</div>
            <div style={mergeStyles(styles.contentBlock,progress>=baseCt+3?inBlock:outBlock,{
                backgroundColor:blockContent.colorB,
                fontSize:blockContent.smallContent?'1.5rem':'2rem'
            })}>
                {renderedBlockContent}
            </div>
            {!blockContent.hideNext&&<button
                style={mergeStyles(styles.contentBlock,styles.nextButton,lastSlideIn?inBlock:outBlock,{
                    backgroundColor:blockContent.colorC,
                })}
                onClick={()=>{
                    loadNextAudio(timeline.findIndex(v=>v.value===timelineItem)+1);
                    setSlideIndex(slideIndex+1);
                }}
            >
                Next <FaChevronRight color="#fff"/>
            </button>}
        </div>
    )

    const renderContent=(
        <div style={styles.root}>
            <ImageLoader relativeToCdn={true} src={preloadImages} onLoaded={onImagesLoaded}/>
            {page}
            {loader}
        </div>
    )

    return <Glitch
        transitionDuration={showLoader?0:600}
        showTo={glitch}
        enabled={false}
        from={renderContent}
        to={
            <Cover>
                Are you <br/> Aware of?
            </Cover>
        }
    />

}

const styles=createStyleSheet({

    root:{
        flex:1,
        backgroundColor:'#fff',
    },


    // Page
    page:{
        flex:1,
        flexDirection:'column'

    },
    contentBlock:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',

        fontSize:'2rem',
        letterSpacing:'0.5rem',

        backgroundSize:'cover',
        backgroundPosition:'center',
        transition:trans('transform'),
        padding:'1rem'
    },
    nextButton:{
        flex:0.4,
        margin:0,
        border:'none',
        color:'#fff',
        justifyContent:'center',
        alignItems:'flex-end',
        padding:0
    },
    

    // Loader
    loadRoot:{
        flex:1,
        position:'absolute',
        left:0,
        top:0,
        minWidth:'100%',
        minHeight:'100%',
        ...flexCenter,
        flexDirection:'column',

        transition:trans('min-width','min-height','left','top')
    },
    loader:{
        flex:1,
        ...flexCenter,
        flexDirection:'column',
    },
    loaderIcon:{
        transition:trans('width','height')
    },
    bar:{
        height:barHeight,
        marginTop:'1rem',
        width:'7rem',
        opacity:1,
        transition:trans('width','height','margin-top','opacity')
    },
    barFill:{
        position:'absolute',
        height:barHeight,
        borderRadius:'1rem',
        backgroundColor:abColor,
        transition:trans({duration:700},'width')
    },
    link:{
        fontSize:'1.5rem',
        borderWidth:0,
        padding:'2rem',
        borderRadius:'10px',
        color:'#fff',
        textDecoration:'none'
    }
});