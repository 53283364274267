import { useState, useEffect } from 'react';

interface CounterProps
{
    value:number;
    pause?:boolean;
    resetOnPause?:boolean;
    duration?:number;
    steps?:number;
    round?:boolean;
    addCents?:boolean;
}

export default function Counter({
    value,
    pause=false,
    resetOnPause=false,
    duration=2000,
    steps=120,
    round=true,
    addCents=false
}:CounterProps):any{


    const [num,setNum]=useState(0);

    useEffect(()=>{

        if(pause){
            if(resetOnPause){
                setNum(0);
            }
            return;
        }

        const inc=value/steps;
        let s=0;
        let m=true;
        const iv=setInterval(()=>{
            if(!m){
                return;
            }

            setNum(n=>n+inc);
            s++;
            if(s>steps){
                clearInterval(iv);
            }
            
        },duration/steps);

        return ()=>{
            m=false;
            clearInterval(iv);
        }

    },[value,pause,duration,steps,resetOnPause]);

    let out=num>value?value:num;

    if(round){
        out=Math.floor(out);
    }else{
        out=Math.floor(out*100)/100;
    }

    let str=out.toString().replace(',','.');

    if(addCents){
        const i=str.indexOf('.');
        if(i===-1){
            str+='.00';
        }else if(i===str.length-2){
            str=str.substring(0,i+1)+'0'+str[str.length-1];
        }
    }

    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}
