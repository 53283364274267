import React, { CSSProperties } from 'react';
import {createStyleSheet, mergeStyles} from '../CommonJs/CommonReact';
import { useWindowSize } from '../CommonJs/WindowSize';
import { Link } from '../CommonJs/Nav';

interface CoverProps
{
    invert?:boolean;
    to?:string;
    style?:CSSProperties;
    backgroundColor?:string;
    foregroundColor?:string;
    children?:any;
}

export default function Cover({
    invert,
    to,
    style,
    backgroundColor='#000000',
    foregroundColor='#dfdfdf',
    children
}:CoverProps){

    if(invert){
        const tmp=backgroundColor;
        backgroundColor=foregroundColor;
        foregroundColor=tmp;

    }

    const {stack}=useWindowSize();

    const _style=mergeStyles(styles.root,{
        fontSize:stack?'2rem':'3rem',
        letterSpacing:stack?'0.5rem':'1.5rem',
        backgroundColor:backgroundColor,
        color:foregroundColor
    },style)

    return (
        to?<Link to={to}>{children}</Link>:<h1 style={_style}>{children}</h1>
    );

}

const styles=createStyleSheet({
    root:{
        flex:1,
        margin:0,
        padding:0,
        minWidth:'100%',
        minHeight:'100%',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        textTransform:'uppercase',
        letterSpacing:'1.5em',
        fontWeight:200,
    }
});
