import React from 'react';
import { useAsync } from './CommonJs/AsyncHooks';
import AwApp from './lib/AwApp';
import {defaultConfig} from './config';
import MainLayout from './components/MainLayout';
import Cover from './components/Cover';
import { AwAppContext } from './lib/hooks';
import { NavRoute } from './CommonJs/Nav';
import Page from './components/Page';
import AirBnBView from './components/AirBnBView';
import Quiz from './components/Quiz';

const quizMatch=/\/quiz\/(\w+)(\/(\d+))?/i;

export default function App()
{

    const app=useAsync(null,async ()=>{
        const app=new AwApp(defaultConfig);
        await app.initAsync();
        return app;
    },[]);

    if(!app){
        return null;
    }

    return (
        <AwAppContext.Provider value={app}>
            <MainLayout>

                <NavRoute path="/">
                    <Page>
                        <AirBnBView src="/airbnb-stacks.json"/> 
                    </Page>
                </NavRoute>

                <NavRoute path="/invert">
                    <Page>
                        <Cover invert to="/" />
                    </Page>
                </NavRoute>

                <NavRoute match={quizMatch} render={(m)=>(
                    <Page>
                        <Quiz
                            quizName={m.stringMatch(1)}
                            questionIndex={m.numberMatch(3)} />
                    </Page>
                )}/>

            </MainLayout>
        </AwAppContext.Provider>
    )

}