import React, { useState, useCallback, useEffect } from 'react';
import {createStyleSheet} from '../CommonJs/CommonReact';
import Img from './Img';

interface ImageLoaderProps
{
    src:string|string[];
    onLoaded?:()=>void;
    relativeToCdn?:boolean;
}

export default function ImageLoader({
    src,
    onLoaded,
    relativeToCdn
}:ImageLoaderProps){

    if(typeof src === 'string'){
        src=[src];
    }

    const [loaded,setLoaded]=useState<string[]|null>([]);

    const onImgLoad=useCallback((s:string,success:boolean)=>{

        if(success){
            console.log(s+' loaded:');
        }else{
            console.warn(s+' load failed:');
        }
        

        setLoaded(loaded=>{
            if(loaded===null){
                return null;
            }
            loaded=[...loaded,s];
            return loaded;
        });
    },[]);

    useEffect(()=>{

        if(loaded===null){
            return;
        }
        let allLoaded=true;
        for(let s of (src as string[])){
            if(loaded.indexOf(s)===-1){
                allLoaded=false;
                break;
            }
        }

        if(allLoaded){
            console.log('All image loaded');
            setLoaded(null);
            if(onLoaded){
                onLoaded();
            }
        }

    },[loaded,src,onLoaded]);

    return (
        <div style={styles.root}>
            {src.map((s,i)=>s&&(
                <Img
                    key={i}
                    alt="pre-loader"
                    src={s}
                    relativeToCdn={relativeToCdn}
                    onLoad={()=>onImgLoad(s,true)}
                    onError={()=>onImgLoad(s,false)} />
            ))}
        </div>
    );

}

const styles=createStyleSheet({
    root:{
        position:'fixed',
        width:0,
        height:0,
        overflow:'hidden'
    }
});
