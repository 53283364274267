import { CSSProperties } from "react";

export const primaryColor='#2e9c6d';
export const foreground='#dddddd';
export const bgColor='#000000';

export const quickTransTime='0.4s';
export const quickTransEase='ease-in-out';

export const quickTrans=quickTransTime+' '+quickTransEase;


export const absFill:CSSProperties={
    position:'absolute',
    left:0,
    top:0,
    right:0,
    bottom:0
}

export const flexCenter:CSSProperties={
    justifyContent:'center',
    alignItems:'center'
}

export interface TransitionConfig
{
    duration?:string|number;
    delay?:string|number;
    easing?:string;
}

const defaultTransConfig:TransitionConfig={
    duration:quickTransTime,
    easing:quickTransEase,
    delay:0
}

export function trans(...props:(keyof CSSProperties|TransitionConfig|string)[]):string
{
    let config=defaultTransConfig;
    let dur=typeof config.duration==='number'?config.duration/1000+'s':(config.duration||quickTransTime);
    let dl=typeof config.delay==='number'?config.delay/1000+'s':(config.delay||'0s');
    let trans='';

    for(let p of props){
        if(typeof p === 'string'){
            trans+=(trans?', ':'')+p+' '+(dur||quickTransTime)+' '+(config.easing||quickTransEase)+' '+dl;
        }else{
            config=p;
            dur=typeof config.duration==='number'?config.duration/1000+'s':(config.duration||quickTransTime);
            dl=typeof config.delay==='number'?config.delay/1000+'s':(config.delay||'0s');
        }
    }

    return trans;
}