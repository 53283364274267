import React, { CSSProperties, useState, useEffect, useMemo, useCallback } from 'react';
import {createStyleSheet, mergeStyles} from '../CommonJs/CommonReact';
import { useWindowSize } from '../CommonJs/WindowSize';
import { trans, absFill } from '../style/style';
import { Link } from '../CommonJs/Nav';
import { useAsync, useQueued } from '../CommonJs/CommonHooks';
import { useApp } from '../lib/hooks';
import Img from './Img';
import { FaChevronRight, FaChevronLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { delayAsync } from '../CommonJs/CommonJs';
import { AudioPlayer } from '../lib/AudioPlayer';

const ABCs='ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const transTime=1000;
const preDelayTime=4500;

const openDelay=4000;

const maxWidth=700;

const defaultStatementSeconds=4;

interface QuestionAnswer
{
    value:string,
    correct?:boolean;
}

interface Question
{
    question:string;
    statement:string;
    statementSeconds?:number;
    background:string;
    audioUri?:string;
    answers:QuestionAnswer[];
}

interface QuizCtx
{
    intro:string;
    introStatement:string;
    introBackground:string;

    summaryStatement:string;
    summaryLink:string;
    summaryLinkText:string;
    summaryBackground:string;

    questions:Question[];
}

interface QuizProps
{
    quizName?:string;
    questionIndex?:number;
}

export default function Quiz({
    quizName,
    questionIndex
}:QuizProps){

    if(questionIndex===undefined){
        questionIndex=0;
    }

    const [ready,setReady]=useState(0);
    useEffect(()=>{
        let m=true;
        const run=async ()=>{
            let i=0;
            
            await delayAsync(100);
            if(!m){return}

            setReady(++i);
            await delayAsync(3000);
            if(!m){return}

            setReady(++i);
        }
        run();
        return ()=>{
            m=false;
        }
    },[]);

    const [,prevIndex]=useQueued(questionIndex);
    const [betweenSlides,setBetweenSlides]=useState(false);
    const [hidePrev,setHidePrev]=useState(false);
    

    const [questionSelected,setQuestionSelected]=useState(false);
    const [skipBetween,setSkipBetween]=useState(0);
    const _skipBetween=useCallback(()=>{
        setSkipBetween(v=>v+1);
    },[]);
    useEffect(()=>{
        setQuestionSelected(false);
    },[questionIndex]);

    const baseRoute='/quiz/'+quizName;

    const {width,height}=useWindowSize();
    const tWidth=width*2;

    const {http}=useApp();

    const ctx=useAsync<QuizCtx,null>(null,async ()=>{
        if(!http || !quizName){
            return [];
        }
        return await http.getAsync('/quiz-'+quizName+'.json');
    },[http,quizName]);

    const questions=ctx?ctx.questions:[];

    const introQuestion=useMemo<Question|null>(()=>{
        if(!ctx){
            return null;
        }
        return {
            question:ctx.intro,
            statement:ctx.introStatement,
            background:ctx.introBackground,
            answers:[]
        }
    },[ctx]);

    const summaryHtml=useMemo(()=>{
        return {__html:(ctx?ctx.summaryStatement:'')||''}
    },[ctx]);

    useEffect(()=>{
        if(prevIndex===undefined || questionIndex===undefined || prevIndex+1!==questionIndex){
            setBetweenSlides(false);
            setHidePrev(false);
            return;
        }
        let m=true;

        const run=async ()=>{
            setBetweenSlides(true);
            setHidePrev(true);

            const question=questions[(questionIndex||0)-2];

            await delayAsync(((question?question.statementSeconds:0)||defaultStatementSeconds)*1000);
            if(!m){return;}

            setBetweenSlides(false);

            await delayAsync(preDelayTime);
            if(!m){return;}

            setHidePrev(false);
        }
        run();
        return ()=>{
            m=false;
        }
    },[prevIndex,questionIndex,questions,skipBetween]);
    useEffect(()=>{
        let m=true;
        if(skipBetween){
            setBetweenSlides(false);
            setTimeout(()=>{
                if(m){
                    setHidePrev(false);
                }
            },preDelayTime);
        }
        return ()=>{
            m=false;
        }
    },[skipBetween]);

    if(!ctx || !introQuestion){
        return null;
    }

    const endIndex=questionIndex===ctx.questions.length+1;

    const showPrev=(!endIndex && questionIndex && (questionIndex>1) && !hidePrev)?true:false;
    const showNext=questionSelected;

    const slideCount=questions.length+2;

    const slideStyle:CSSProperties={
        display:'flex',
        position:'absolute',
        flexDirection:'column',
        left:0,
        top:0,
        width,
        height
    }

    const selectedIndex=(questionIndex||0)+(betweenSlides?-1:0);

    let w=width;
    let x=0;
    if(w>maxWidth){
        x=(width-maxWidth)/2;
        w=maxWidth;
    }


    return (
        <div style={styles.root}>
            <div style={{
                display:'flex',
                position:'relative',
                flex:1,

                transition:trans({duration:transTime},'transform'),
                transform:'translateX('+((-tWidth*questionIndex)+(betweenSlides?width:0))+'px)'
            }}>

                <div style={slideStyle}>
                    <QuizQuestion
                        isIntro={true}
                        question={introQuestion}
                        index={-1}
                        selectedIndex={selectedIndex}
                        count={questions.length}
                        width={width}
                        height={height}
                        tWidth={tWidth}
                        baseRoute={baseRoute}
                        betweenSlides={betweenSlides}
                        setQuestionSelected={setQuestionSelected}/>
                </div>

                {questions.map((q,i)=>(
                    <QuizQuestion
                        key={i}
                        question={q}
                        index={i}
                        selectedIndex={selectedIndex}
                        count={questions.length}
                        width={width}
                        height={height}
                        tWidth={tWidth}
                        baseRoute={baseRoute}
                        betweenSlides={betweenSlides}
                        setQuestionSelected={setQuestionSelected}
                        skipBetween={_skipBetween}/>
                ))}


                <div style={mergeStyles(slideStyle,{
                    justifyContent:'center',
                    left:tWidth*(slideCount-1),
                    backgroundImage:'url('+ctx.summaryBackground+')',
                    backgroundSize:'cover',
                    backgroundPosition:'center'
                })}>

                    <h1 style={styles.summaryHeader} dangerouslySetInnerHTML={summaryHtml} />

                    {ctx.summaryLink&&ctx.summaryLinkText&&
                        <Link
                            tabIndex={endIndex?undefined:-1}
                            to={ctx.summaryLink}
                            real={true}
                            style={styles.summaryBtn}>{ctx.summaryLinkText}</Link>    
                    }

                    <Link real style={styles.restartBtn} tabIndex={endIndex?undefined:-1} to={baseRoute}>
                        <MdRefresh size={40}/>
                    </Link>

                </div>

            </div>
            <div style={mergeStyles(styles.bottomButtons,{
                transform:'translate('+x+'px)',
                maxWidth:w,
                width:w
            })}>
                <Link
                    tabIndex={showPrev?undefined:-1}
                    to={!showPrev?undefined:betweenSlides?undefined:baseRoute+(questionIndex===1?'':'/'+(questionIndex-1))}
                    style={mergeStyles(styles.navBtn,showPrev?null:{
                        transform:'translateY(6rem)'
                    })}><FaChevronLeft/> Previous</Link>
                <Link
                    tabIndex={showNext?undefined:-1}
                    to={!showNext?undefined:betweenSlides?undefined:baseRoute+'/'+(questionIndex+1)}
                    style={mergeStyles(styles.navBtn,styles.navBtnNext,showNext?null:{
                        transform:'translateY(6rem)'
                    })}>Next <FaChevronRight/></Link>
            </div>

            <div style={mergeStyles(styles.loadCover,{
                opacity:ready===2?0:1,
                visibility:ready===2?'hidden':'visible'
            })}>
                <span style={{marginBottom:'3rem'}}>Pop Quiz<br/>Hot Shot</span> <AiOutlineQuestionCircle size={60}/>
            </div>
        </div>
    );

}

interface QuizQuestionProps
{
    index:number;
    selectedIndex:number;
    count:number;
    question:Question;
    width:number;
    height:number;
    tWidth:number;
    baseRoute:string;
    betweenSlides:boolean;
    setQuestionSelected:(value:boolean)=>void;
    skipBetween?:()=>void;
    isIntro?:boolean;
}

const flipStatement=false;

function QuizQuestion({
    index,
    selectedIndex,
    count,
    question,
    width,
    height,
    tWidth,
    baseRoute,
    betweenSlides,
    setQuestionSelected,
    skipBetween,
    isIntro
}:QuizQuestionProps)
{

    const {config}=useApp();

    const active=index+1===selectedIndex;
    const isOpen=index+1<=selectedIndex;
    const tabIndex=active?0:-1;

    const [selected,setSelected]=useState(-1);

    const correct=question.answers.find(q=>q.correct);

    const audioPlayer=useMemo(()=>new AudioPlayer(),[])
    useEffect(()=>{
        if(active && betweenSlides){
            audioPlayer.play();
        }else{
            audioPlayer.pause();
        }
    },[active,betweenSlides,audioPlayer])

    const answerClick=useCallback((i:number)=>{
        setSelected(i);
        setQuestionSelected(true);
        let uri=question.audioUri;
        if(uri){
            uri=uri.replace('@cdn/',config.CdnBaseUrl);
            audioPlayer.load(uri);
        }
    },[question,audioPlayer,config,setQuestionSelected]);

    let statement=question.statement;
    if(statement){
        statement=statement.split('{{VALUE}}').join(correct?correct.value:'');
    }
    const statementHtml=useMemo(()=>({__html:statement}),[statement]);

    let w=width;
    let x=0;
    if(w>maxWidth){
        x=(width-maxWidth)/2;
        w=maxWidth;
    }

    return (
        <>
            <div style={{
                display:'flex',
                position:'absolute',
                flexDirection:'column',
                justifyContent:isIntro?'center':'flex-start',
                left:tWidth*(index+1)+(flipStatement?width:0)+x,
                top:0,
                width:w,
                minHeight:'100%',
                zIndex:2,
            }}>

                

                <div style={isIntro?undefined:{
                    transition:trans({delay:openDelay},'transform'),
                    transform:`translateY(${isOpen?0:height/4}px)`
                }}>
                    <h2 style={isIntro?styles.questionIntroHeader:styles.questionHeader}>{question.question}</h2>
                </div>

                {question.answers.length>0&&<ul style={styles.answerList}>
                    {question.answers.map((a,i)=>(
                        <li
                            key={i}
                            style={styles.answerItem}>
                            <button
                                tabIndex={tabIndex}
                                onClick={()=>answerClick(i)}
                                style={mergeStyles(
                                        styles.answerItemBtn,
                                        i===0?styles.roundStart:(i===question.answers.length-1?styles.roundEnd:null),
                                        i===selected?styles.answerSelectedBtn:null,
                                        {
                                            transition:trans('background-color',{delay:openDelay+i*100},'transform'),
                                            transform:`translateY(${isOpen?0:height}px)`
                                        }
                                )}>
                                <span style={styles.anNum}>{ABCs[i]}</span> {a.value}
                            </button>
                        </li>
                    ))}
                </ul>}

                {isIntro&&<Link to={baseRoute+'/1'} tabIndex={tabIndex} style={styles.summaryBtn}>Start Quiz</Link>}
            </div>

            <div style={{
                display:'flex',
                position:'absolute',
                flexDirection:'column',
                justifyContent:'center',
                left:tWidth*(index+1)+(flipStatement?0:width)+x,
                top:0,
                width:w,
                minHeight:'100%',
                zIndex:2,
            }}>
                <h1 style={styles.questionStatement} dangerouslySetInnerHTML={statementHtml}/>
                {skipBetween&&<button style={styles.skipBtn} onClick={skipBetween}>
                    <span style={{marginRight:'0.5rem'}}>continue</span>
                    <FaAngleDoubleRight/>
                </button>}
            </div>

            <div style={{
                opacity:isOpen?(betweenSlides?0.2:0.6):0,
                transition:trans('opacity')
            }}>
                <Img alt="Question Background" src={question.background} style={{
                    position:'absolute',
                    left:tWidth*(index+1),
                    top:0,
                    objectFit:'cover',
                    width:tWidth,
                    height,
                    zIndex:1,

                    opacity:isOpen?1:0.2,
                    transition:trans({delay:openDelay,duration:1000},'opacity')
                }}/>
            </div>

        </>
    )
}

const panelBg:CSSProperties={
    backgroundColor:'rgba(0,0,0,0.8)',
    backdropFilter:'blur(8px)'
}

const styles=createStyleSheet({
    root:{
        flex:1,
        flexDirection:'column'
    },
    bottomButtons:{
        flexDirection:'row',
        justifyContent:'space-between'
    },
    navBtn:{
        transition:trans('transform'),
        ...panelBg,
        padding:'1rem',
        textDecoration:'none',
        color:'#fff',
        outline:'none',
        flex:1,
        alignItems:'center',
        justifyContent:'center',
    },
    navBtnNext:{
        borderWidth:'0 0 0 1px',
        borderColor:'#555',
        borderStyle:'solid'
    },
    restartBtn:{
        ...panelBg,
        padding:'0.5rem',
        borderRadius:'1rem',
        position:'absolute',
        right:'1rem',
        top:'1rem',
        color:'#fff',
    },
    summaryHeader:{
        padding:'3rem 1rem',
        fontSize:'2rem',
        textAlign:'center',
        justifyContent:'center',
        ...panelBg,
    },
    summaryBtn:{
        ...panelBg,
        alignSelf:'center',
        outline:'none',
        padding:'2rem',
        margin:'1rem',
        borderRadius:'1rem',
        textDecoration:'none',
        fontSize:'2rem',
        color:'#fff',
        borderWidth:0

    },
    questionHeader:{
        margin:0,
        padding:'1rem',
        fontSize:'1.5rem',
        ...panelBg,
        transition:trans({duration:transTime},'transform')
    },
    questionIntroHeader:{
        padding:'1rem',
        margin:'1rem',
        borderRadius:'1rem',
        fontSize:'1.5rem',
        ...panelBg,
    },
    questionStatement:{
        fontSize:'2.5rem',
        textAlign:'center',
        fontWeight:100,
        display:'inline-block',
        margin:'1rem'

    },
    answerList:{
        listStyle:'none',
        flexDirection:'column',
        margin:'1rem',
        padding:0,
        flex:1
    },
    answerItem:{
        flex:1,
        margin:'0',
        transition:trans({duration:transTime},'transform')
        
    },
    answerItemBtn:{
        flex:1,
        alignItems:'center',
        ...panelBg,
        padding:'0 1rem',
        fontSize:'1.5rem',
        color:'#fff',
        borderWidth:'1px 0 0 0',
        borderColor:'#555',
        borderRadius:'0',
        outline:'none',
        margin:0
    },
    skipBtn:{
        ...panelBg,
        borderStyle:'none',
        color:'#fff',
        padding:'1rem 1.5rem',
        alignSelf:'center',
        marginTop:'3rem',
        borderRadius:'1rem',
        alignItems:'center',
        fontSize:'12px',
        opacity:0.8
    },
    roundStart:{
        borderWidth:0,
        borderRadius:'1rem 1rem 0 0'
    },
    roundEnd:{
        borderRadius:'0 0 1rem 1rem'
    },
    answerSelectedBtn:{
        backgroundColor:'rgba(0,50,0,0.8)'
        
    },
    anNum:{
        fontSize:'3rem',
        marginRight:'1rem',
        color:'#555'
    },

    loadCover:{
        ...absFill,
        backgroundColor:'#000',
        zIndex:10,
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        color:'#fff',
        textTransform:'uppercase',
        fontSize:'2rem',
        fontWeight:200,

        transition:trans('opacity','visibility')
    }
});
