import React, { useState, useEffect } from 'react';
import {createStyleSheet, mergeStyles} from '../CommonJs/CommonReact';
import { delayAsync } from '../CommonJs/CommonJs';
import { absFill, trans } from '../style/style';

interface GlitchProps
{
    // Initial component shown
    from:any;

    // Component to glitch to
    to:any;

    timing?:number[],

    enabled?:boolean;

    showTo?:boolean;

    transitionDuration?:number;
}

export default function Glitch({
    from,
    to,
    timing,
    enabled=true,
    showTo:forceShowTo=false,
    transitionDuration=100
}:GlitchProps){

    const [_showTo,setShowTo]=useState(false);

    useEffect(()=>{
        if(!enabled){
            return;
        }
        let m=true;

        const an=async ()=>{

            while(m){

                if(timing){

                    for(let t of timing){
                        await delayAsync(t);if(!m)return;
                        setShowTo(v=>!v);
                    }
                    await delayAsync(3000);if(!m)return;

                }else{

                    setShowTo(v=>!v);
                    await delayAsync(Math.random()*2000+100);if(!m)return;

                }
            }
        }

        an();

        return ()=>{
            m=false;
        }
    },[enabled,timing]);

    const showTo=forceShowTo||(enabled?_showTo:false);

    return (
        <div style={styles.root}>
            <div style={styles.from}>
                {from}
            </div>
            <div style={mergeStyles(styles.to,{
                opacity:showTo?1:0,
                filter:showTo?'blur(0px)':'blur(15px)',
                visibility:showTo?'visible':'hidden'
            },transitionDuration?({transition:trans({duration:transitionDuration},'opacity','filter','visibility')}):null)}>
                {to}
            </div>
        </div>
    );

}

const styles=createStyleSheet({
    root:{
        flex:1,
    },
    from:{
        ...absFill
    },
    to:{
        ...absFill,
        
    }
});
