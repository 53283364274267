import React, { useCallback, useState, useLayoutEffect, CSSProperties } from 'react';
import { useApp } from '../lib/hooks';

export const CdnPrefix='@cdn/';

const getSrcSet=(src:string|null|undefined,size:string)=>{
    if(!src || !size){
        return undefined;
    }

    const i=src.lastIndexOf('.');
    if(i===-1){
        return src+'@'+size;
    }

    return src.substring(0,i)+'@'+size+src.substring(i)+' '+size;
}

interface ImgProps
{
    src?:string|null;
    alt:string;
    x2?:boolean;
    className?:string;
    notFound?:string;
    srcSet?:string;
    cdn?:boolean;
    onLoad?:()=>void;
    onError?:()=>void;
    style?:CSSProperties;
    relativeToCdn?:boolean;
}

export default function Img({
    src,
    alt,
    x2,
    className,
    notFound,
    srcSet,
    cdn,
    onLoad:_onLoad,
    onError:_onError,
    style,
    relativeToCdn
}:ImgProps){

    const {config}=useApp();

    if(cdn===undefined){
        cdn=src?src.startsWith(CdnPrefix):false;
    }

    const hasProto=src && src.indexOf('://')!==-1;

    if(relativeToCdn && src && !hasProto && src[0]!=='/'){
        src=CdnPrefix+src;
        cdn=true;
    }

    if(cdn && src && !hasProto){
        src=config.CdnBaseUrl+src.substring(CdnPrefix.length);
    }

    const [loaded,setLoaded]=useState(false);
    const [srcProxy,setSrcProxy]=useState(src);
    useLayoutEffect(()=>{
        setSrcProxy(src);
        setLoaded(false);
    },[src]);

    const onError=useCallback(()=>{
        setSrcProxy('/images/not-found'+(notFound?'-'+notFound:'')+'.png');
        if(_onError){
            _onError();
        }
    },[notFound,_onError]);

    const onLoaded=useCallback(()=>{
        setLoaded(true);
        if(_onLoad){
            _onLoad();
        }
    },[_onLoad]);

    const _srcSet=srcSet?srcSet:(x2?getSrcSet(src,'2x'):undefined);

    return (
        <img
            alt={alt}
            style={style}
            className={loaded?className:className+' loading-bg'}
            src={srcProxy||undefined}
            srcSet={_srcSet}
            onError={onError}
            onLoad={onLoaded}/>
    )

}
