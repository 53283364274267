import EventEmitterEx from '../CommonJs/EventEmitterEx';
import { AwConfig } from './AwTypes';
import { delayAsync } from '../CommonJs/CommonJs';
import { defaultNav, Nav } from '../CommonJs/Nav';
import Http from '../CommonJs/Http';

export default class AwApp extends EventEmitterEx
{

    public readonly app:AwApp;

    public readonly config:AwConfig;

    public readonly history:Nav;

    public readonly http:Http;

    constructor(config:AwConfig)
    {
        super();
        this.app=this;
        this.config=config;
        this.history=defaultNav;
        this.http=new Http('');
    }

    public async initAsync():Promise<void>
    {
        await delayAsync(1);
    }
}