export class AudioPlayer
{
    private _src:string|null=null;
    private _elem:HTMLAudioElement|null=null;
    private _actionId:number=0;
    private _isPlaying:boolean=false;

    public get src():string|null{return this._src}

    public load(src:string|null)
    {
        if(src===this._src){
            return;
        }

        if(this._elem){
            try{
                this._elem.pause();
            }catch(ex){
                console.warn('pause for load audio player failed. src='+this._src,ex);
            }
        }
        this._src=src;
        const id=++this._actionId;
        if(src){
            this._elem=new Audio(src);
            try{
                this._elem.play();
                this._isPlaying=true;
            }catch(ex){
                console.warn('play for load audio player failed. src='+this._src,ex);
            }
            setTimeout(()=>{
                try{
                    if(id===this._actionId){
                        this._elem?.pause();
                        this._isPlaying=false;
                    }
                }catch(ex){
                    console.warn('pause for audio player failed. src='+this._src,ex);
                }
            },10);
        }else{
            this._elem=null;
        }
    }

    public play(delay?:number)
    {
        const id=++this._actionId;
        if(!this._elem || this._isPlaying){
            return;
        }
        if(delay && delay>0){
            setTimeout(()=>{
                if(id!==this._actionId){
                    return;
                }
                try{
                    this._elem?.play();
                    this._isPlaying=true;
                }catch(ex){
                    console.warn('play with delay audio player failed. delay='+delay+'. src='+this._src,ex);
                }
            },delay);
        }else{
            try{
                this._elem.play();
                this._isPlaying=true;
            }catch(ex){
                console.warn('play audio player failed. src='+this._src,ex);
            }
        }
    }

    public pause()
    {
        this._actionId++;
        if(!this._elem || !this._isPlaying){
            return;
        }
        try{
            this._elem.pause();
            this._isPlaying=false;
        }catch(ex){
            console.warn('play audio player failed. src='+this._src,ex);
        }
    }
}