import React from 'react';
import {createStyleSheet} from '../CommonJs/CommonReact';

interface PageProps
{
    children?:any;
}

export default function Page({
    children
}:PageProps){

    return (
        <div style={styles.root} className="nav-trans-target">
            {children}
        </div>
    );

}

const styles=createStyleSheet({
    root:{
        flex:1,
    }
});
