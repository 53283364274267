import React from 'react';
import {createStyleSheet} from '../CommonJs/CommonReact';
import {bgColor, foreground} from '../style/style';

interface MainLayoutProps
{
    children?:any;
}

export default function MainLayout({
    children
}:MainLayoutProps){

    return (
        <div style={styles.root}>
            {children}
        </div>
    )

}

const styles=createStyleSheet({
    root:{
        flex:1,
        color:foreground,
        backgroundColor:bgColor
    }
});